// Function that builds News Sliders
const buildNewsSlider = sliderElm => {
    const sliderIdentifier = sliderElm.dataset.id;
    return new Swiper(`.news-swiper[data-id="${sliderIdentifier}"]`, {
        slidesPerView: 1,
        spaceBetween: 15,
        a11y: true,
        grabCursor: true,

        navigation: {
            nextEl: `.button-next-${sliderIdentifier}`,
            prevEl: `.button-prev-${sliderIdentifier}`,
        },

        scrollbar: {
            el: `.swiper-scrollbar-${sliderIdentifier}`,
            draggable: true,
        },

        freeMode: {
            enabled: true,
            sticky: false,
        },

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },

        breakpoints: {
            // when window width is >= 700px
            700: {
            slidesPerView: 2,
            spaceBetween: 30
            },
            // when window width is >= 1050px
            1050: {
            slidesPerView: 3,
            spaceBetween: 60
            }
        }
    });
}

// Get all of the news sliders on the page
const allNewsSliders = document.querySelectorAll('.news-swiper');

// Loop over all of the fetched sliders and apply Swiper on each one.
allNewsSliders.forEach(slider => buildNewsSlider(slider));



// Function that builds Testimonial Sliders
const buildTestimonialSlider = sliderElm => {
    const sliderIdentifier = sliderElm.dataset.id;
    return new Swiper(`.testimonial-swiper[data-id="${sliderIdentifier}"]`, {
        slidesPerView: 1.15,
        spaceBetween: 15,
        a11y: true,
        grabCursor: true,

        navigation: {
            nextEl: `.button-next-${sliderIdentifier}`,
            prevEl: `.button-prev-${sliderIdentifier}`,
        },

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },

        breakpoints: {
            // when window width is >= 700px
            700: {
            slidesPerView: 1.3,
            spaceBetween: 25
            },
            // when window width is >= 1050px
            1050: {
            slidesPerView: 1.5,
            spaceBetween: 40
            }
        }
    });
}

// Get all of the news sliders on the page
const allTestimonialSliders = document.querySelectorAll('.testimonial-swiper');

// Loop over all of the fetched sliders and apply Swiper on each one.
allTestimonialSliders.forEach(slider => buildTestimonialSlider(slider));



// Function that builds Alumni Sliders
const buildAlumniQuoteSlider = sliderElm => {
    const sliderIdentifier = sliderElm.dataset.id;
    var sliderQuote = new Swiper(`.alumni-quote-swiper[data-id="${sliderIdentifier}"]`, {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 0,
        a11y: true,
        grabCursor: true, 
        
        /*
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        */

        navigation: {
            nextEl: `.button-next-${sliderIdentifier}`,
        },

        pagination: {
            el: ".alumni-pagination",
        },

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },
    });

    var sliderPortrait = new Swiper(`.alumni-portrait-swiper[data-id="${sliderIdentifier}"]`, {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 0,
        a11y: true,
        grabCursor: true,

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },
    });

    sliderQuote.controller.control = sliderPortrait;
    sliderPortrait.controller.control = sliderQuote; 
    
    const playButton = sliderElm.querySelector('.play-slider');
    const pauseButton = sliderElm.querySelector('.pause-slider');

    if (typeof(playButton) != 'undefined' && playButton != null) {
        playButton.addEventListener("click", function(){
            sliderQuote.autoplay.start();
            sliderPortrait.autoplay.start();
            playButton.classList.add('visuallyhidden');
            pauseButton.classList.remove('visuallyhidden');
        } , false);
    }

    if (typeof(pauseButton) != 'undefined' && pauseButton != null) {
        pauseButton.addEventListener("click", function(){
            sliderQuote.autoplay.stop();
            sliderPortrait.autoplay.stop();
            pauseButton.classList.add('visuallyhidden');
            playButton.classList.remove('visuallyhidden');
        } , false);
    }
}

// Get all of the news sliders on the page
const allAlumniQuoteSliders = document.querySelectorAll('.alumni-quote-swiper');

// Loop over all of the fetched sliders and apply Swiper on each one.
allAlumniQuoteSliders.forEach(slider => buildAlumniQuoteSlider(slider));




// Function that builds Testimonial Sliders
const buildImgRowSlider = sliderElm => {
    const sliderIdentifier = sliderElm.dataset.id;
    return new Swiper(`.inner--logo-row[data-id="${sliderIdentifier}"]`, {
        slidesPerView: 'auto',
        spaceBetween: 64,
        a11y: true,
        grabCursor: true,
        freeMode: true,
        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },

        navigation: {
            nextEl: `.button-next-${sliderIdentifier}`,
        },
    });
}

// Get all of the news sliders on the page
const allImgRowSliders = document.querySelectorAll('.inner--logo-row');

// Loop over all of the fetched sliders and apply Swiper on each one.
allImgRowSliders.forEach(slider => buildImgRowSlider(slider));